import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormlyModule } from '@ngx-formly/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { FormlyFieldButton } from './Formly/custom-types/button-type.component';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { NavbarComponent } from './component/navbar/navbar.component';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuModule } from 'primeng/menu';
import { CommonModule } from '@angular/common';
// import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ChipModule } from 'primeng/chip';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarToggleButtonComponent } from './component/navbar/sidebar-toggle-button/sidebar-toggle-button.component';
import { CountryInterceptor } from './shared/Interceptors/countryInterceptors/country.interceptor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SharedModule } from './module/shared/shared.module';
import { CustomToastComponent } from './component/custom-toast/custom-toast.component';
import { AuthInterceptor } from './shared/auth.interceptor';
import { tokenInterceptor } from './shared/token.interceptor';
import { ProfileComponent } from './module/profile/profile.component';
import { TabViewModule } from 'primeng/tabview';
import { FormlyFieldFileUploadComponent } from './module/users/types/formly-field-file-upload.component';
import { PasswordComponent } from './module/password/password.component';
import { FormlyHorizontalWrapper } from './horizontal-wrappers';
import { MessagesModule } from 'primeng/messages';
import { FormlyFieldPhotoUploadComponent } from './module/users/types/profilepicture-type';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarToggleButtonComponent,
    CustomToastComponent,
    ProfileComponent,
    PasswordComponent,
    FormlyHorizontalWrapper,
    FormlyFieldPhotoUploadComponent,
  ],
  imports: [
    ConfirmDialogModule,
    TooltipModule,
    HttpClientModule,
    TabViewModule,
    IconFieldModule,
    InputTextModule,
    InputIconModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormlyPrimeNGModule,
    AppRoutingModule,
    ButtonModule,
    MenubarModule,
    CardModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ChipModule,
    ToastModule,
    MessagesModule,

    // FormlyModule.forRoot({
    //   types: [{ name: 'input', extends: 'input', wrappers: ['form-field'] }],
    //   validators: [],
    //   validationMessages: [],
    // }),
    // FormlyModule.forRoot({
    //   wrappers: [
    //     { name: 'form-field-horizontal', component: FormlyHorizontalWrapper },
    //   ],
    //   validationMessages: [
    //     { name: 'required', message: 'This field is required' },
    //   ],
    // }),
    FormlyModule.forChild({
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        {
          name: 'passwordStrength',
          message:
            'Password must be at least 6 characters long and contain at least one uppercase letter.',
        },
      ],
      validators: [{ name: 'passwordStrength', validation: passwordValidator }],
      wrappers: [
        { name: 'form-field-horizontal', component: FormlyHorizontalWrapper },
      ],
      types: [
        {
          name: 'file-photoupload',
          component: FormlyFieldPhotoUploadComponent,
        },
      ],
    }),
    SidebarModule,
    PanelMenuModule,
    MenuModule,
    FileUploadModule,
    ToastModule,
  ],
  exports: [],
  providers: [
    ConfirmationService,
    MessageService,
    DynamicDialogRef,
    DialogService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: CountryInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: tokenInterceptor,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// Custom Validator for Password Strength
export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    // Check for minimum length
    const minLength = value.length >= 6;

    // Check for at least one uppercase letter
    const hasUpperCase = /[A-Z]/.test(value);

    const passwordValid = minLength && hasUpperCase;

    return !passwordValid ? { passwordStrength: true } : null;
  };
}
