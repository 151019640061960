import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UserService } from '../../Service/User/user.service';
import { FormStateService } from '../service-provider/add-sp-new-component/form-state.service';
import { ServiceAdvisorFormStateService } from '../service-advisors/service/service-advisor-formStateService';
import { ToastService } from '../../Service/ToastService/toast.service';
import { Router } from '@angular/router';
import { ServiceProviderService } from '../../Service/Service-providers/service-provider.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  form = new FormGroup({});
  model: any = {
    firstName: '',
    lastName: '',
    email: '',
    userProfilePhotoId: '',
  };
  options: FormlyFormOptions = {};
  isLoading = false;

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'p-fluid p-formgrid p-grid',
      fieldGroup: [
        {
          fieldGroupClassName: 'field grid p-fluid',
          fieldGroup: [
            {
              className: 'field col',
              type: 'input',
              wrappers: ['form-field-horizontal'],
              key: 'firstName',
              templateOptions: {
                placeholder: 'First Name',
                required: true,
                label: 'Name',
              },
            },
            {
              className: 'field col',
              key: 'lastName',
              type: 'input',
              templateOptions: {
                placeholder: 'Last Name',
                label: 'Name',
                required: true,
                hideLabel: true,
              },
            },
          ],
        },
        {
          template: '<div class="horizontal-line"></div>', // Horizontal line between sections
        },
        {
          template: `<div class="mt-3 "></div>`,
        },

        {
          key: 'email',
          wrappers: ['form-field-horizontal'],
          type: 'input',
          templateOptions: {
            label: 'Email address',
            type: 'email',
            placeholder: 'example@domain.com',
            required: true,
            // hideLabel: true,
          },
          validators: {
            email: {
              expression: (c: AbstractControl) =>
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                  c.value
                ),
              message: (error: any, field: FormlyFieldConfig) =>
                `${field.formControl?.value} is not a valid Email Address`,
            },
          },
        },
        {
          template: `<div class="mt-3"></div>`,
        },
        {
          key: 'userProfilePhotoId',
          wrappers: ['form-field-horizontal'],
          type: 'file-photoupload',
          templateOptions: {
            label: 'Your photo',
            placeholder: 'Click to upload or drag and drop',
            description: 'SVG, PNG, JPG or GIF (max. 800x400px)',
            className: 'upload-photo',
          },
        },
      ],
    },
  ];
  data: any;
  constructor(
    private api: UserService,
    private serviceAPI: ServiceProviderService,
    private serviceAdvisorFormStateService: ServiceAdvisorFormStateService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}
  ngOnInit() {
    this.profilDetails();
  }
  profilDetails() {
    this.api.getLogiProfile().subscribe((res: any) => {
      this.patchProfileInfo(res);
    });
  }

  patchProfileInfo(res: any) {
    this.model.firstName = res?.firstName || '';
    this.model.lastName = res?.lastName || '';
    this.model.email = res?.emailAddress || '';

    const photo = res.profilePhoto;
    if (photo && photo.filePath) {
      const fileUrl = `${this.api.api}/${photo.filePath.replace(/^\//, '')}`;
      console.log('File URL :', fileUrl);

      // Set profile picture in the form state service
      this.serviceAdvisorFormStateService.setProfilePicture({
        fileName: photo.fileName,
        fileUrl: fileUrl,
        fileId: photo.userProfilePhotoId,
      });

      this.model.userProfilePhotoId = fileUrl;
    }

    this.form.patchValue(this.model);
    this.cdr.detectChanges();
  }

  onSubmit() {
    const data = this.prepareUpdateData(this.form.value);
    console.log(data);
    this.api.updateProfile(data).subscribe(
      (res: any) => {
        const token: any = localStorage.getItem('token');

        console.log(token);
        this.serviceAPI.newToken(token).subscribe((res: any) => {
          console.log(res);
          this.serviceAPI.storeToken(token);
          this.serviceAPI.storePersonalDetails(token);
          this.api.updateProfileData(res);
        });

        this.handleSuccess('Profile Page updated successfully');
      },
      (error) => {
        console.log(error);
        this.handleError('An error occurred while updating. Please try again.');
      }
    );
  }

  prepareUpdateData(profileData: any) {
    const uploadedFileId =
      this.serviceAdvisorFormStateService.getUploadedFileId('profilePic');

    return {
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      userProfilePhotoId: uploadedFileId,
    };
  }
  onCancel() {
    this.router.navigate(['/service-provider']);
  }
  handleSuccess(message: string): void {
    this.isLoading = false;
    this.toastService.showToast(message, 'success');
    setTimeout(() => {
      this.router.navigate(['/service-provider']);
    }, 1500);
  }

  handleError(message: string): void {
    this.isLoading = false;
    this.toastService.showToast(message, 'error');
  }
}
