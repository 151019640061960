<div class="change-password-container">
  <div class="personal-info">
    <h3>Change Password</h3>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form
      [model]="model"
      [fields]="fields"
      [options]="options"
      [form]="form"
    ></formly-form>

    <div style="margin-top: 32px">
      <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
          <button
            pButton
            type="button"
            label="Reset"
            class="p-button-secondary"
            (click)="onReset()"
          ></button>
        </div>
        <div class="flex align-items-center justify-content-center m-2">
          <button
            pButton
            type="submit"
            label="Save"
            class="p-button-warning"
            [disabled]="form.invalid"
          ></button>
        </div>
      </div>
    </div>
  </form>
</div>
