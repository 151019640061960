import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from '../../Service/ToastService/toast.service';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss'],
})
export class CustomToastComponent {
  toast = { message: '', type: 'success', visible: false };

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastService.toastState$.subscribe((toast) => {
      this.toast = toast;
    });
  }

  getToastClass() {
    return {
      'toast-success': this.toast.type === 'success',
      'toast-error': this.toast.type === 'error',
    };
  }

  hideToast() {
    this.toastService.hideToast();
  }
}
