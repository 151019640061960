import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Output,
  output,
} from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ServiceProviderService } from '../../Service/Service-providers/service-provider.service';
import { Router } from '@angular/router';
import { UserService } from '../../Service/User/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit {
  items!: MenuItem[];
  isSidebarVisible: boolean = false;
  bottomItems!: MenuItem[];
  name: string | null = '';
  phoneNumber: string | null = '';
  imgPath = '';
  activeMenu: string = '';
  initials: string = '';
  data: any;
  email: any;
  profilePhotoUrl: any;
  constructor(
    public api: ServiceProviderService,
    private route: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private api1: UserService,
    private cdr: ChangeDetectorRef
  ) {
    this.imgPath = 'assets/image/Avatar.png';
  }

  ngOnInit() {
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.phoneNumber = tokenDetails.phoneNumber;
    }
    this.api1.profile$.subscribe((profile) => {
      if (profile) {
        this.getLoggedInUser();
      }
    });
    this.getLoggedInUser();
  }
  profilDetails() {
    this.api1.getLogiProfile().subscribe((res: any) => {
      this.name = `${res.firstName} ${res.lastName}`;
      this.initials = this.getInitials(res.firstName, res.lastName);
    });
  }
  getInitials(firstName: string, lastName: string): string {
    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  }
  setActiveMenu(menu: string): void {
    this.activeMenu = menu;
    console.log(this.activeMenu);
  }
  logOut() {
    this.api.logout();
    this.route.navigate(['login']);
  }
  confirm1(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.logout();
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'You have been logged out',
        });
      },
      reject: () => {},
    });
  }

  logout() {
    localStorage.removeItem('tokenDetails');
    localStorage.removeItem('businessTypes');
    localStorage.removeItem('token');

    localStorage.clear();
    sessionStorage.clear();
    this.redirectToLogin();
  }
  redirectToLogin() {
    this.route.navigate(['/login']).then(() => {
      // Reloading the window to clear any lingering state
      window.location.reload();
    });
  }
  getLoggedInUser() {
    const domain = this.api.api;

    this.api.getLoggedInUser().subscribe((res: any) => {
      // Ensure profilePhoto and filePath exist
      if (res.profilePhoto && res.profilePhoto.filePath) {
        const path = res.profilePhoto.filePath;
        this.profilePhotoUrl = `${domain}/${path}`;
        console.log(this.profilePhotoUrl);
      } else {
        this.profilePhotoUrl = null;
      }

      // Set the name and calculate initials
      this.name = `${res.firstName} ${res.lastName}`;
      this.initials = this.getInitials(res.firstName, res.lastName); // Calculate initials here
      this.email = res.emailAddress;
    });
  }

  truncateEmail(email: string, maxLength: number): string {
    if (email?.length > maxLength) {
      return email.substring(0, maxLength) + '...';
    }
    return email;
  }
}
