<div class="sidebar">
  <div class="content">
    <div class="top-box">
      <div class="img">
        <div class="flex justify-content-start flex-wrap">
          <div class="flex align-items-center justify-content-center">
            <img src="assets/image/Logomark.png" alt="Logo" class="svg-black" />
          </div>
          <div class="flex align-items-center justify-content-center ml-2">
            <p style="color: #1d2939; font-weight: 700; font-size: 16px">
              Auto E Care
            </p>
          </div>
        </div>
      </div>
      <nav class="menu">
        <ul>
          <li class="menu-item" routerLink="/service-provider" (click)="setActiveMenu('serviceProviders')"
            style="cursor: pointer">
            <a [ngClass]="{ active: activeMenu === 'serviceProviders' }" routerLinkActive="active">
              <i class="icon pi pi-list"></i> Service Providers
            </a>
          </li>
          <li class="menu-item" routerLink="/service-advisors" (click)="setActiveMenu('service-advisors')"
            style="cursor: pointer">
            <a [ngClass]="{ active: activeMenu === 'service-advisors' }" routerLinkActive="active">
              <i class="icon pi pi-users"></i> Service Advisors
            </a>
          </li>

          <li class="menu-item" routerLink="/service-seekers" (click)="setActiveMenu('customers')"
            style="cursor: pointer">
            <a [ngClass]="{ active: activeMenu === 'customers' }" routerLinkActive="active">
              <i class="icon pi pi-users"></i> Service Seekers
            </a>
          </li>
          <li class="menu-item" (click)="setActiveMenu('invoicing')" style="cursor: pointer">
            <span [ngClass]="{ active: activeMenu === 'invoicing' }">
              <i class="icon pi pi-receipt"></i> Invoicing
            </span>
          </li>
        </ul>
      </nav>
    </div>
    <div class="bottom-menu">
      <ul>
        <li class="menu-item" style="padding-left: 20px; cursor: pointer">
          <span (click)="confirm1($event)">
            <i class="icon pi pi-power-off m-2"></i> Log Out
          </span>
        </li>
      </ul>
      <div style="padding-bottom: 32px; margin-left: 10px">
        <div class="bottom-box">
          <div class="user-info">
            <div class="icon-circle" routerLink="/profile" style="cursor: pointer;">
              <img [src]="profilePhotoUrl" class="image" alt="{{initials}}" />
              <!-- <div class="initials" pTooltip="" tooltipPosition="top">{{ initials }}</div> -->
            </div>
            <div class="username ml-3">
              <p class="name" pTooltip="{{ name }}">{{ name }}</p>
              <p class="email" pTooltip="{{ email }}">
                {{ truncateEmail(email, 10) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog></p-confirmDialog>