import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ChangeDetectorRef } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ServiceAdvisorFormStateService } from '../../service-advisors/service/service-advisor-formStateService';
import { UserService } from '../../../Service/User/user.service';

@Component({
  selector: 'formly-field-photo-upload',
  template: `
    <p-toast position="bottom-center"></p-toast>
    <div
      class="file-upload"
      [ngClass]="{ 'file-upload-hover': isHovering }"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (click)="fileInput.click()"
    >
      <input
        type="file"
        #fileInput
        (change)="onFileSelect($event)"
        style="display: none;"
      />
      <!-- Display when no file is selected -->
      <ng-container *ngIf="!fileName && !isUploading">
        <i class="pi pi-cloud-upload" style="font-size:2.5rem;"></i>
        <p class="top">
          <span id="click">Click to upload</span> or drag and drop
        </p>
        <p class="svg">PNG, JPG, JPEG (max. 800x400px)</p>
      </ng-container>

      <!-- Display when a file is selected or loaded from saved data -->
      <ng-container *ngIf="fileName && !isUploading">
        <ng-container *ngIf="isImageFile(fileName)">
          <img
            [src]="fileUrl"
            alt="Selected file"
            class="uploaded-image"
            width="500"
            height="600"
          />
        </ng-container>
        <ng-container *ngIf="!isImageFile(fileName)">
          <img
            src="assets/image/pdf-icon.png"
            alt="PDF Icon"
            class="pdf-icon"
            style="width: 100px; height: auto;"
          />
          <p>{{ fileName }}</p>
        </ng-container>
      </ng-container>

      <!-- Display during file upload -->
      <ng-container *ngIf="isUploading">
        <div class="spinner-container">
          <div class="spinner"></div>
          <div class="progress-text">{{ uploadProgress }}%</div>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .top {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      #click {
        color: var(--Brand-700, #d47500);
      }
      .svg {
        color: var(--Gray-600, #475467);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
      .file-upload {
        border: 2px dashed #e4e7ec;
        padding: 16px 24px;
        text-align: center;
        cursor: pointer;
        transition: border-color 0.3s ease;
        height: 287px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
      }
      .file-upload-hover {
        border-color: #ff9f2a;
      }
      .file-upload:hover {
        border-color: #ff9f2a;
      }
      .file-upload p {
        margin: 0;
        color: #666;
      }
      .uploaded-image {
        max-width: 463px;
        max-height: 255px;
        margin-bottom: 10px;
      }
      .remove-button {
        background: none;
        border: none;
        color: #b42318;
        cursor: pointer;
        font-size: 14px;
        top: 110px;
        position: absolute;
        right: 30px;
      }
      .spinner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .spinner {
        border: 4px solid #ff8c00;
        border-top: 4px solid #ff8c00;
        border-radius: 50%;
        width: 58px;
        height: 58px;
        animation: spin linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .progress-text {
        margin-top: 10px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        color: #ff8c00;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldPhotoUploadComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  isHovering = false;
  fileName: string | undefined;
  fileUrl: string | undefined;
  isUploading = false;
  uploadProgress = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private fileService: ServiceAdvisorFormStateService,
    private api: UserService,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit() {
    const savedData = this.fileService.getProfilePicture();
    if (savedData) {
      this.fileName = savedData.fileName;
      this.fileUrl = savedData.fileUrl;
      this.formControl.setValue(savedData.fileId || savedData.fileUrl);
      this.cdr.detectChanges();
    }
    if (this.formControl.value && typeof this.formControl.value === 'string') {
      this.fileUrl = this.formControl.value;
      console.log(this.fileUrl);
      this.fileName = this.extractFileNameFromUrl(this.fileUrl);
      this.cdr.detectChanges();
    }
  }
  extractFileNameFromUrl(fileUrl: string): string {
    return fileUrl.split('/').pop() || 'File';
  }
  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.fileName = file.name;
      this.fileUrl = URL.createObjectURL(file);
      this.formControl.setValue(this.fileUrl);

      this.fileService.setProfilePicture({
        file,
        fileName: this.fileName,
        fileUrl: this.fileUrl,
      });

      this.uploadFile(file);
    }
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('FileSourceId', '4');

    this.isUploading = true;
    this.uploadProgress = 0;

    this.api.uploadFile(formData).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress && event.total) {
          this.uploadProgress = Math.round((100 * event.loaded) / event.total);
          this.cdr.detectChanges();
        } else if (event.type === HttpEventType.Response) {
          const fileId = event.body.fileId;
          if (fileId) {
            this.fileService.setProfilePictureFileId(fileId);
            this.formControl.setValue(fileId);
            this.fileService.setProfilePicture({
              fileName: this.fileName,
              fileUrl: this.fileUrl,
              fileId: fileId,
            });
          } else {
            console.error('File ID is missing in response');
          }
          this.isUploading = false;
          this.cdr.detectChanges();
        }
      },
      (error) => {
        console.error('Error uploading file', error);
        this.isUploading = false;
        this.cdr.detectChanges();
      }
    );
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isHovering = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isHovering = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isHovering = false;
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      const file = files[0];
      this.fileName = file.name;
      this.fileUrl = URL.createObjectURL(file);
      this.formControl.setValue(this.fileUrl);
      this.fileService.setProfilePicture({
        file,
        fileName: this.fileName,
        fileUrl: this.fileUrl,
      });
      this.uploadFile(file);
    }
  }

  removeFile(event: Event) {
    event.stopPropagation();
    this.fileName = undefined;
    this.fileUrl = undefined;
    this.formControl.setValue(null);
    this.fileService.setProfilePicture(null);
    this.fileInput.nativeElement.value = '';
    this.cdr.detectChanges();
  }

  isImageFile(fileName: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png'];
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return imageExtensions.includes(fileExtension || '');
  }
}
