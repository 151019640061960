import { Component } from '@angular/core';
import { ServiceProviderService } from '../../../Service/Service-providers/service-provider.service';

@Component({
  selector: 'app-sidebar-toggle-button',
  templateUrl: './sidebar-toggle-button.component.html',
  styleUrl: './sidebar-toggle-button.component.scss'
})
export class SidebarToggleButtonComponent {
  isSidebarVisible = false;

  constructor(private api: ServiceProviderService) {
  }


}
