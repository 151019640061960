import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/auth.guard';
import { ServiceProvidersDetails2Component } from './module/service-provider/service-providers-details-2/service-providers-details-2.component';
import { UserDetailsComponent } from './module/users/user-details/user-details.component';
import { ServiceAdvisorDetailsComponent } from './module/service-advisors/service-advisor-details/service-advisor-details.component';
import { ProfileComponent } from './module/profile/profile.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./module/login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'service-seekers',
    loadChildren: () =>
      import('./module/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'roles',
    loadChildren: () =>
      import('./module/roles/roles.module').then((m) => m.RolesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./module/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'service-provider',
    loadChildren: () =>
      import('./module/service-provider/service-provider.module').then(
        (m) => m.ServiceProviderModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'service-provider/:serviceProviderId',
    component: ServiceProvidersDetails2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'service-seekers/:customerId',
    component: UserDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'service-advisors',
    loadChildren: () =>
      import('./module/service-advisors/service-advisors.module').then(
        (m) => m.ServiceAdvisorsModule
      ),
  },
  {
    path: 'service-advisors/:serviceAdvisorId',
    component: ServiceAdvisorDetailsComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled', useHash: true }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
