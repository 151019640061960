<!-- profile-settings.component.html -->
<div class="settings-header">
  <p-tabView>
    <p-tabPanel header="Profile">
      <div class="personal-info">
        <h3>Personal info</h3>
        <p>Update your personal details here.</p>
      </div>
      <hr class="custom-hr" />

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <formly-form
          [form]="form"
          [fields]="fields"
          [model]="model"
          [options]="options"
        ></formly-form>
        <div class="button-group">
          <button
            pButton
            type="button"
            label="Cancel"
            class="p-button-secondary"
            (click)="onCancel()"
          ></button>
          <button
            pButton
            type="submit"
            label="Save"
            class="p-button-warning"
          ></button>
        </div>
      </form>
    </p-tabPanel>
    <p-tabPanel header="Password">
      <!-- Password update logic can be implemented here -->
      <app-password></app-password>
    </p-tabPanel>
  </p-tabView>
</div>
