// country.interceptor.ts

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CountryInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get countryId from localStorage or wherever you store it
    const countryId = localStorage.getItem('countryId');

    // Clone the request and add headers if countryId exists
    if (countryId) {
      const modifiedReq = req.clone({
        setHeaders: {
          'X-Country-Id': countryId,
        },
      });
      return next.handle(modifiedReq);
    }

    return next.handle(req);
  }
}
