import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ServiceProviderService } from '../Service/Service-providers/service-provider.service';

@Injectable()
export class tokenInterceptor implements HttpInterceptor {
  constructor(private authService: ServiceProviderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    let modifiedReq = req;

    if (token) {
      modifiedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
      });
    }
    // console.log('Request URL:', modifiedReq.url);
    return next.handle(modifiedReq).pipe(
      catchError((error) => {
        console.error('HTTP Request error:', error);
        return throwError(error);
      })
    );
  }
}
