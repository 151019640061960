import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-horizontal-wrapper',
  template: `
    <div class="field grid">
      <label
        [attr.for]="id"
        class="p-col-fixed p-mr-2"
        *ngIf="props.label"
        style="width: 150px;"
      >
        {{ props.label }}
        <ng-container> :</ng-container>
      </label>
      <div class="col">
        <ng-template #fieldComponent></ng-template>
        <!-- Validation message directly under the input field -->
        <div
          *ngIf="showError"
          class="invalid-feedback d-block"
          style="color:red; margin-top: 4px;"
        >
          <formly-validation-message
            [field]="field"
          ></formly-validation-message>
        </div>
      </div>
    </div>
  `,
})
export class FormlyHorizontalWrapper extends FieldWrapper {
  get errorMessage() {
    return this.field?.validation?.messages
      ? this.field.validation.messages
      : 'This is required field';
  }
}
