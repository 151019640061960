import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { passwordValidator } from '../../app.module';
import { UserService } from '../../Service/User/user.service';
import { Router } from '@angular/router';
import { ToastService } from '../../Service/ToastService/toast.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrl: './password.component.scss',
})
export class PasswordComponent {
  form = new FormGroup({});
  model = { oldPassword: '', newPassword: '', confirmPassword: '' };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'p-fluid p-formgrid p-grid',
      fieldGroup: [
        {
          key: 'oldPassword',
          type: 'input',

          props: {
            label: ' CurrentPassword',
            type: 'password',
            placeholder: 'Must be at least 6 characters',
            required: true,
            minLength: 6,
          },
        },
        {
          template: `<div class="mt-3 "></div>`,
        },
        {
          key: 'newPassword',
          type: 'input',
          props: {
            label: 'New Password',
            type: 'password',
            required: true,
            placeholder: 'Must be at least 6 characters',
          },
          validators: {
            validation: [passwordValidator()],
          },
          validation: {
            messages: {
              passwordStrength:
                'Password must be at least 6 characters long and contain at least one uppercase letter.',
            },
          },
        },
        {
          template: `<div class="mt-3 "></div>`,
        },
        {
          key: 'confirmpassword',
          type: 'input',
          props: {
            label: 'Confirm Password',
            type: 'password',
            required: true,
          },
          validators: {
            validation: [
              {
                expression: (control: { value: string }) =>
                  control.value === this.model.newPassword,
                message: 'Passwords do not match',
              },
            ],
          },
        },
      ],
    },
  ];
  isLoading = false;
  constructor(
    private api: UserService,
    private router: Router,
    private toastService: ToastService
  ) {}
  onSubmit() {
    if (this.form.valid) {
      const updateData = {
        oldPassword: this.model.oldPassword,
        newPassword: this.model.newPassword,
      };
      this.api.updatePassword(updateData).subscribe(
        () => {
          this.handleSuccess('Profile Page updated successfully');
        },
        () => {
          this.handleError(
            'An error occurred while updating. Please try again.'
          );
        }
      );
    }
  }
  handleSuccess(message: string): void {
    this.isLoading = false;
    this.toastService.showToast(message, 'success');
    setTimeout(() => {
      this.router.navigate(['/service-provider']);
    }, 1500);
  }

  handleError(message: string): void {
    this.isLoading = false;
    this.toastService.showToast(message, 'error');
  }
  onReset() {
    this.form.reset();
    this.model = { oldPassword: '', newPassword: '', confirmPassword: '' }; // Optionally reset the model
  }
}
