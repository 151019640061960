import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceProviderService } from './Service/Service-providers/service-provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'Autocare-web';
  isSidebarVisible = false;

  constructor(private router: Router, private api: ServiceProviderService) {}

  loggedIn() {
    if (this.router.url === '/login') {
      return false;
    } else {
      return this.api.isLoggedIn();
    }
  }
}
